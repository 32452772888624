import React from "react";
import '../assets/styles/global.css'

const Imprint = () => {
    return (
        <div className="imprint">
            <p>test</p>
            <p>test</p>
            <p>test</p>
            <p>test</p>
        </div>
    );
};

export default Imprint;