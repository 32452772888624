import React, { useEffect, useRef } from 'react';
import "../assets/styles/global.css";

const AnimationCard = ({ gifSource, altText, link, description }) => {
    const projectRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate');
                    } else {
                        entry.target.classList.remove('animate');
                    }
                });
            },
            { threshold: 0.35 } // Adjust threshold as necessary
        );

        if (projectRef.current) {
            observer.observe(projectRef.current);
        }

        return () => {
            if (projectRef.current) {
                observer.unobserve(projectRef.current);
            }
        };
    }, []);

    return (
        <a href={link} className='project' ref={projectRef}>
            <img
                src={gifSource}
                alt={altText}
                className="animationCard"
            />
            <p className='projectDescription'>{description}</p>
        </a>
    );
};

export default AnimationCard;
