import React from 'react';
import HomePage from './main-elements/homepage.js';

const App = () => {
  return (
    <HomePage />
  );
};

export default App;
