import React from 'react';
import AnimationCard from './AnimationCard';
import Imprint from './Imprint';
import '../assets/styles/global.css';
import GifTypetrainer from '../assets/animation-cards/UX-Owlio-2.gif';
import GifPlaceholder from '../assets/animation-cards/UX-Owlio-Placeholder-2.gif';

const HomePage = () => {
  const subSites = ['typetrainer-web', 'test', 'test', 'test', 'test'];
  const descriptions = [
    'A simple website for creating and practicing typing exercises', 
    'This is a placeholder, but it might soon lead somewhere!', 
    'This is a placeholder, but it might soon lead somewhere!', 
    'This is a placeholder, but it might soon lead somewhere!', 
    'This is a placeholder, but it might soon lead somewhere!'
  ];
  const gifs = [GifTypetrainer, GifPlaceholder, GifPlaceholder, GifPlaceholder, GifPlaceholder];

  return (
    <div>
      <h1 className='homepageHeader'>WELCOME TO OWLIO</h1>
      <div className='refCards'>
        {subSites.map((site, index) => (
          <AnimationCard
            key={`${site}-${index}`}  // Using a combination of site name and index for a unique key
            gifSource={gifs[index]}
            altText={site}
            link={`http://www.owlio.at/sub-sites/${site.toLowerCase()}/`}  // Full URL to the sub-site
            description={descriptions[index]}
          />
        ))}
      </div>
      <Imprint />
    </div>
  );
};

export default HomePage;
